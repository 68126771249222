import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const AsteriskIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.097 6.375h-2.194v4.398l-4.226-1.37L6 11.474l4.194 1.37-2.581 3.538 1.774 1.243L12 14.088l2.613 3.537 1.774-1.243-2.58-3.538L18 11.474l-.677-2.071-4.226 1.37V6.375Z'
      fill={colourMap[colour]}
    />
    <path
      fillRule='evenodd'
      d='M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11Zm-2.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default AsteriskIcon;
