import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const DemoIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={colourMap[colour]}
      d='M11.318 5H19v6h-5.083a5.891 5.891 0 0 1-.219.882A8.445 8.445 0 0 1 15.557 14H22V2H7v2.083A6.04 6.04 0 0 1 8 4c1.227 0 2.367.368 3.318 1Z'
    />
    <path
      fill={colourMap[colour]}
      fillRule='evenodd'
      d='M11.06 12.577a4 4 0 1 0-5.86.28 6.398 6.398 0 0 0-3.013 7.084A10.354 10.354 0 0 0 8.4 22c2.33 0 4.48-.766 6.213-2.06a6.413 6.413 0 0 0-3.554-7.363ZM8 11.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm.4 8c-1.42 0-2.75-.373-3.9-1.027V18.4a3.9 3.9 0 1 1 7.8.073A7.854 7.854 0 0 1 8.4 19.5Z'
    />
  </svg>
);

export default DemoIcon;
