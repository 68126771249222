import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import Footer from '../../../components/Footer';

import styles from './styles.module.scss';

//TODO probably figure out a better name for this
const SimpleMessageView = ({
  darkBackground = true,
  children,
  isMainLandmark = true,
}) => {
  const Tag = isMainLandmark ? 'main' : 'div';
  return (
    <div styleName='outerContainer'>
      <Tag styleName={`background ${darkBackground ? ' dark' : ''}`}>
        <div styleName='contentContainer'>{children}</div>
      </Tag>
      <Footer darkBackground={darkBackground} />
    </div>
  );
};

SimpleMessageView.propTypes = {
  darkBackground: PropTypes.bool,
  children: PropTypes.any.isRequired,
  isMainLandmark: PropTypes.bool,
};

export default CSSModules(SimpleMessageView, styles, {allowMultiple: true});
