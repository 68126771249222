import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const PlusIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.5 10.5V4h-3v6.5H4v3h6.5V20h3v-6.5H20v-3h-6.5Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default PlusIcon;
