import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const AlertIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.8 10v5h2.4v-5h-2.4ZM10.8 18v-2h2.4v2h-2.4Z'
      fill={colourMap[colour]}
    />
    <path
      fillRule='evenodd'
      d='m12 1 12 21H0L12 1ZM4.3 19.5 12 6l7.7 13.5H4.3Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default AlertIcon;
