import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const ChartIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M5 5v14h17v3H2V2h3v3Zm5 4H7v8h3V9Zm2-4h3v12h-3V5Zm8 2h-3v10h3V7Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default ChartIcon;
