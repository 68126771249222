import React from 'react';

import {compact, join, map} from 'lodash';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import xorWith from 'lodash/xorWith';

import {
  ACTION_SUBTYPE_COMPLETED_COURSE,
  ACTION_SUBTYPE_COMPLETED_GUIDE,
  ACTION_TYPE_COURSE,
  ACTION_TYPE_GUIDE,
  AND_OPERATOR,
  BOTH_OPERATORS,
  DAYS,
  NESTED_RULE,
  OR_OPERATOR,
  RULE_COLUMN_NAMES,
  RULE_CRITERION_OPERATORS,
  RULE_ID_COLUMNS,
  RULE_TABLES,
  RULE_TYPE_TAG,
  SINGLE_RULE,
  SMART_TEAM_UPDATE_STATUS,
  smartTeamIsPending,
  TAG_TYPE_EDUME_ACTION,
} from '@edume/bento/smartTeamsEnums';
import {PeopleIcon, SmartTeamIcon} from '@edume/magnificent';

export const getTeamIcon = (
  isSmartTeam,
  color = 'grey700',
  size = 'xsmall'
) => {
  if (isSmartTeam) {
    return <SmartTeamIcon colour={color} size={size} />;
  }
  return <PeopleIcon colour={color} size={size} />;
};

/**
 * @param {{id:number, isSmartTeam:boolean}} team
 * @param {{underlyingTeamId:number}[]} segments
 */
export const getSegmentByTeam = (team, segments) =>
  team.isSmartTeam &&
  segments.find((segment) => segment.underlyingTeamId === team.id);

/**
 * Filters out smart teams from a list of teams
 * @param {[]} teams
 */
export const filterToStaticTeams = (teams) =>
  teams.filter(({isSmartTeam}) => !isSmartTeam);

/**
 * Filters out static teams from a list of teams
 * @param {[]} teams
 */
export const filterToSmartTeams = (teams) =>
  teams.filter(({isSmartTeam}) => isSmartTeam);

export const teamsContainsSmartTeam = (smartTeamId, teams) =>
  !!teams.find((team) => team.id === smartTeamId && team.isSmartTeam);

export const getTeamIconFromId = (teamId, teams) =>
  getTeamIcon(teamsContainsSmartTeam(teamId, teams));

/**
 * returns both smart and static teams if {@param smartTeamsEnabled} is true, else only static teams
 * @param {[]} teams
 * @param {boolean} smartTeamsEnabled
 */
export const filterSmartTeams = (teams, smartTeamsEnabled) => {
  if (smartTeamsEnabled) {
    return teams;
  }
  return filterToStaticTeams(teams);
};

export const filterAllUsersTeam = (teams, allUsersTeam) =>
  teams.filter(({id}) => id !== allUsersTeam?.id);

export const getTimePeriodLabel = ({type, value, intl}) => {
  const intlMessage = (id, values) => intl.formatMessage({id}, values);
  if (!type) {
    return null;
  }
  return intlMessage(`Teams.createSmartTeam.timePeriod.${type}`, {
    value,
  });
};

export const getTimePeriodKey = ({elapsedTimeType, elapsedTimeValue, intl}) => {
  const intlMessage = (id) => intl.formatMessage({id});
  const timePeriodLabel = getTimePeriodLabel({
    type: elapsedTimeType,
    value: elapsedTimeValue,
    intl,
  });
  const allTimeKey = intlMessage('Teams.createSmartTeam.condition.anytime');

  return elapsedTimeValue
    ? `${intlMessage(
        'Teams.createSmartTeam.condition.after'
      )} ${elapsedTimeValue} ${timePeriodLabel.toLowerCase()}`
    : allTimeKey;
};

const logos = {
  adp: '/resources/img/logo/adp.svg',
  beekeeper: '/resources/img/logo/beekeeper.svg',
  msTeams: '/resources/img/logo/msTeams.svg',
  paycor: '/resources/img/logo/paycor.svg',
  salesforce: '/resources/img/logo/salesforce.svg',
  sap: '/resources/img/logo/sap.svg',
  speakap: '/resources/img/logo/speakap.svg',
  workday: '/resources/img/logo/workday.svg',
  dayforce: '/resources/img/logo/dayforce.svg',
  hubspot: '/resources/img/logo/hubspot.svg',
  [TAG_TYPE_EDUME_ACTION]: '/resources/img/logo/edume.svg',
};

/**
 * @param {string} [type]
 * @returns {string | undefined}
 */
export const getTagIcon = (type) => {
  const integrationName = getIntegrationNameFromType(type);
  return logos[integrationName];
};

/**
 * @param {string} [type='']
 * @returns {string}
 */
export const getIntegrationNameFromType = (type = '') => {
  if (type === TAG_TYPE_EDUME_ACTION) {
    return TAG_TYPE_EDUME_ACTION;
  }

  return type.includes('.') ? type.split('.')[0] : '';
};

const addTagCriteria = ({id}) => ({
  table: RULE_TABLES.TAGS,
  where: [
    {
      op: RULE_CRITERION_OPERATORS.EQUALS,
      column: RULE_ID_COLUMNS.TAGS,
      value: id,
    },
  ],
  user_id_column: RULE_ID_COLUMNS.USERS,
});

const addCourseCriteria = ({
  id,
  startDate,
  endDate,
  elapsedTimeType,
  elapsedTimeValue,
  score,
}) => {
  const whereArray = [];

  if (startDate) {
    whereArray.push({
      op: RULE_CRITERION_OPERATORS.AFTER,
      column: RULE_COLUMN_NAMES.COURSE_COMPLETION_DATE,
      value: startDate,
    });
  }

  if (endDate) {
    whereArray.push({
      op: RULE_CRITERION_OPERATORS.BEFORE,
      column: RULE_COLUMN_NAMES.COURSE_COMPLETION_DATE,
      value: endDate,
    });
  }

  if (elapsedTimeValue) {
    whereArray.push({
      op: RULE_CRITERION_OPERATORS.AFTER_ELAPSED_TIME,
      column: RULE_COLUMN_NAMES.COURSE_COMPLETION_DATE,
      value: [elapsedTimeValue, elapsedTimeType],
    });
  }

  if (score) {
    whereArray.push({
      op: RULE_CRITERION_OPERATORS.SCORED_BELOW,
      column: RULE_COLUMN_NAMES.COURSE_LESSON_SCORES,
      value: score,
    });
  }

  whereArray.push({
    op: RULE_CRITERION_OPERATORS.EQUALS,
    column: RULE_ID_COLUMNS.COURSES,
    value: id,
  });

  return {
    table: RULE_TABLES.COURSE_COMPLETION,
    where: whereArray,
    user_id_column: RULE_ID_COLUMNS.USERS,
  };
};

const addGuideCriteria = ({
  id,
  startDate,
  endDate,
  elapsedTimeType,
  elapsedTimeValue,
}) => {
  const whereArray = [];

  if (startDate) {
    whereArray.push({
      op: RULE_CRITERION_OPERATORS.AFTER,
      column: RULE_COLUMN_NAMES.GUIDE_COMPLETION_DATE,
      value: startDate,
    });
  }

  if (endDate) {
    whereArray.push({
      op: RULE_CRITERION_OPERATORS.BEFORE,
      column: RULE_COLUMN_NAMES.GUIDE_COMPLETION_DATE,
      value: endDate,
    });
  }

  if (elapsedTimeValue) {
    whereArray.push({
      op: RULE_CRITERION_OPERATORS.AFTER_ELAPSED_TIME,
      column: RULE_COLUMN_NAMES.GUIDE_COMPLETION_DATE,
      value: [elapsedTimeValue, elapsedTimeType],
    });
  }

  whereArray.push({
    op: RULE_CRITERION_OPERATORS.EQUALS,
    column: RULE_ID_COLUMNS.GUIDES,
    value: id,
  });

  return {
    table: RULE_TABLES.GUIDE_COMPLETION,
    where: whereArray,
    user_id_column: RULE_ID_COLUMNS.USERS,
  };
};

export const generateRulesPayload = (rules, operator) => {
  const criteria = rules.map((rule) => {
    if (rule.type === RULE_TYPE_TAG) {
      if (rule.criteria) {
        const nestedCriteria = rule.criteria.map((criterion) =>
          addTagCriteria(criterion)
        );
        return {
          criteria: nestedCriteria,
          op: rule.ruleOperator.value,
        };
      } else {
        return addTagCriteria(rule);
      }
    }
    if (rule.type === ACTION_TYPE_COURSE) {
      return addCourseCriteria({
        ...rule,
        elapsedTimeType: rule.value?.elapsedTimeType,
        elapsedTimeValue: rule.value?.elapsedTimeValue,
        score: rule.value.score,
      });
    }
    if (rule.type === ACTION_TYPE_GUIDE) {
      return addGuideCriteria({
        ...rule,
        elapsedTimeType: rule.value?.elapsedTimeType,
        elapsedTimeValue: rule.value?.elapsedTimeValue,
      });
    }
    return null;
  });

  return {
    op: operator.value,
    criteria,
  };
};

const getContentId = (criterion, ruleColumn) =>
  get(
    criterion.where.find((w) => w.column === ruleColumn),
    'value'
  );

const createRawRule = ({
  contentId,
  isCourseCompletionEvent,
  content,
  intlMessage,
}) => ({
  id: contentId,
  type: isCourseCompletionEvent ? ACTION_TYPE_COURSE : ACTION_TYPE_GUIDE,
  title: content?.title,
  label: isCourseCompletionEvent
    ? intlMessage('Teams.createSmartTeam.edumeActions.completedCourse')
    : intlMessage('Teams.createSmartTeam.edumeActions.completedGuide'),
  subtype: isCourseCompletionEvent
    ? ACTION_SUBTYPE_COMPLETED_COURSE
    : ACTION_SUBTYPE_COMPLETED_GUIDE,
});

// eslint-disable-next-line complexity
const processCriterion = (criterion, tags, courses, guides, intl) => {
  const intlMessage = (id) => intl.formatMessage({id});

  const isCourseCompletionEvent =
    criterion.table === RULE_TABLES.COURSE_COMPLETION;
  const isGuideCompletionEvent =
    criterion.table === RULE_TABLES.GUIDE_COMPLETION;
  const rawRules = [];

  if (criterion.table === RULE_TABLES.TAGS) {
    const tagId = get(criterion, 'where[0].value');
    const tag = tags?.find((t) => t.id === tagId);
    const tagType = tag?.tagType;
    const label = tag?.title;
    const pendingRule = {
      id: tagId,
      type: RULE_TYPE_TAG,
      label,
      tagType,
    };

    rawRules.push(pendingRule);
  }
  if (isCourseCompletionEvent || isGuideCompletionEvent) {
    const contentId = isCourseCompletionEvent
      ? getContentId(criterion, RULE_ID_COLUMNS.COURSES)
      : getContentId(criterion, RULE_ID_COLUMNS.GUIDES);

    const content = isCourseCompletionEvent
      ? courses?.find((c) => c.id === contentId)
      : guides?.find((g) => g.id === contentId);

    let rawRule = createRawRule({
      contentId,
      isCourseCompletionEvent,
      content,
      intlMessage,
    });

    for (const where of criterion.where) {
      switch (where.op) {
        case RULE_CRITERION_OPERATORS.AFTER:
          rawRule = {
            ...rawRule,
            startDate: new Date(where.value),
            value: {
              elapsedTimeValue: '',
              elapsedTimeType: DAYS,
            },
          };
          break;

        case RULE_CRITERION_OPERATORS.AFTER_ELAPSED_TIME:
          rawRule = {
            ...rawRule,
            value: {
              elapsedTimeValue: where.value[0],
              elapsedTimeType: where.value[1],
            },
          };
          break;

        case RULE_CRITERION_OPERATORS.SCORED_BELOW:
          rawRule = {
            ...rawRule,
            value: {
              ...rawRule.value,
              score: where.value,
            },
          };
          break;
        default:
          break;
      }
    }
    rawRules.push(rawRule);
  }

  if (criterion.criteria) {
    const nestedRules = [];
    for (const nestedCriterion of criterion.criteria) {
      nestedRules.push(
        ...processCriterion(nestedCriterion, tags, courses, guides, intlMessage)
      );
    }
    const ruleOperator = {
      translationKey: `Teams.createSmartTeam.operator.${criterion.op}`,
      value: criterion.op,
    };
    rawRules.push({
      ruleOperator,
      criteria: nestedRules,
      type: RULE_TYPE_TAG,
    });
  }

  return rawRules;
};

// the rules are stored in a different format in the backend
// we need to get the raw rules to display the rules in the UI
export const getRawRules = (existingRules, tags, courses, guides, intl) => {
  let rawRules = [];

  for (const criterion of existingRules.criteria) {
    rawRules.push(...processCriterion(criterion, tags, courses, guides, intl));
  }

  return rawRules;
};

/**
 * @returns {{partialMissingTags: boolean, allTagsMissing: boolean}
 */
const getMissingTagsStatus = (rules, tags) => {
  if (!Object.keys(tags).length) {
    return {
      missing: [],
      all: [],
    };
  }

  const tagIds = rules.criteria
    .filter((rule) => rule.table === RULE_TABLES.TAGS)
    .map(getTagIdFromRule);

  const missingTags = tagIds.filter((tagId) => !tags[tagId]?.value);

  const partialMissingTags =
    missingTags.length > 0 && missingTags.length < tagIds.length;

  const allTagsMissing =
    missingTags.length > 0 && missingTags.length === tagIds.length;

  return {
    partialMissingTags,
    allTagsMissing,
  };
};

/**
 * @typedef {object} IssueType
 * @property {boolean} hasTagsMissing
 * @property {boolean} hasTagsMissingPartial
 * @property {boolean} hasServerError
 */

/**
 * @typedef {object} StatusObject
 * @property {boolean} pending
 * @property {boolean} error
 * @property {boolean} partialError
 * @property {IssueType} type
 */

/**
 * @param {object} obj
 * @param {boolean} obj.pending
 * @param {boolean} obj.error
 * @param {boolean} obj.partialError
 * @param {IssueType} [obj.type]
 * @returns {StatusObject}
 **/
const createStatusObject = ({
  pending = false,
  error = false,
  partialError = false,
  type,
}) => ({
  pending,
  error,
  partialError,
  type,
});

/**
 *
 * @param {*} rule
 * @param {*} tags
 * @param {*} defaultReturn
 * @returns {StatusObject}
 */
const getRuleStatus = (rule, tags, defaultReturn) => {
  const {partialMissingTags, allTagsMissing} = getMissingTagsStatus(rule, tags);

  const isANDOperator = rule.op === AND_OPERATOR.value;
  /**
   * If the rule has an OR statement partial tags, then display a warning, since
   * we can calculate team membership based on the 'OR' part of the rule.
   *
   * If all the tags are missing, or an AND operator is used then the team
   * membership cannot be calculated
   */
  if (allTagsMissing || (partialMissingTags && isANDOperator)) {
    return createStatusObject({error: true, type: {hasTagsMissing: true}});
  }

  if (partialMissingTags && !isANDOperator) {
    return createStatusObject({
      partialError: true,
      type: {hasTagsMissingPartial: true},
    });
  }

  return defaultReturn;
};

/**
 * @param {object} smartTeam
 * @param {object[]} [tags]
 */
export const getSmartTeamStatus = (smartTeam, tags) => {
  if (!smartTeam) {
    return createStatusObject({});
  }

  const defaultReturn = createStatusObject({
    pending: smartTeamIsPending(smartTeam),
  });

  const {status} = smartTeam;

  if (status === SMART_TEAM_UPDATE_STATUS.ERROR) {
    return createStatusObject({error: true, type: {hasServerError: true}});
  }

  if (tags && smartTeam?.rules) {
    return getRuleStatus(smartTeam.rules, tags, defaultReturn);
  }

  return defaultReturn;
};

export const getTagIdFromRule = (rule) => {
  const tagIdColumn = rule.where.find(
    (where) => where.column === RULE_ID_COLUMNS.TAGS
  );
  return tagIdColumn?.value;
};

export const getCourseIdFromRule = (rule) => {
  const courseIdColumn = rule.where.find(
    (where) => where.column === RULE_ID_COLUMNS.COURSES
  );
  return courseIdColumn?.value;
};

export const getGuideIdFromRule = (rule) => {
  const guideIdColumn = rule.where.find(
    (where) => where.column === RULE_ID_COLUMNS.GUIDES
  );
  return guideIdColumn?.value;
};

export const getElapsedTimeTypeAndValueFromRule = (rule) => {
  const elapsedTimeColumn = rule.where.find(
    (where) => where.op === RULE_CRITERION_OPERATORS.AFTER_ELAPSED_TIME
  );
  return {
    elapsedTimeValue: elapsedTimeColumn?.value[0],
    elapsedTimeType: elapsedTimeColumn?.value[1],
  };
};

export const getScoreFromRule = (rule) => {
  const scoreColumn = rule.where.find(
    (where) => where.op === RULE_CRITERION_OPERATORS.SCORED_BELOW
  );
  return scoreColumn?.value;
};

/**
 * @param {string} operator
 * @returns {object}
 * @throws {Error}
 */
export const getExistingOperator = (operator) => {
  switch (operator) {
    case AND_OPERATOR.value:
      return AND_OPERATOR;
    case OR_OPERATOR.value:
      return OR_OPERATOR;
    default:
      throw new Error(`Unknown operator: ${operator}`);
  }
};

export const getRuleTypes = (rules) => {
  const ruleTypes = {
    tag: rules.some((rule) => rule.type === RULE_TYPE_TAG),
    event: rules.some(
      (rule) =>
        rule.type === ACTION_TYPE_COURSE || rule.type === ACTION_TYPE_GUIDE
    ),
    logicLayer: SINGLE_RULE,
    ruleLogic: null,
  };

  const rulesWithMultipleCriterion = rules.filter(
    (rule) => rule.criteria?.length > 1
  );

  if (rulesWithMultipleCriterion.length > 0) {
    const ruleOperators = rulesWithMultipleCriterion.map(
      (rule) => rule.ruleOperator?.value
    );
    const hasAndOperator = ruleOperators.includes(AND_OPERATOR.value);
    const hasOrOperator = ruleOperators.includes(OR_OPERATOR.value);

    ruleTypes.ruleLogic =
      hasAndOperator && hasOrOperator ? BOTH_OPERATORS : ruleOperators[0];

    const hasNestedRules = rulesWithMultipleCriterion.some(
      (rule) => rule.criteria.length > 1
    );

    ruleTypes.logicLayer = hasNestedRules ? NESTED_RULE : SINGLE_RULE;
  }

  return ruleTypes;
};

/**
 * @param {Array} existingRules
 * @param {Array} newRules
 * @param {object} existingOperator
 * @param {object} newOperator
 * @returns {boolean}
 */
export const checkIfExistingRulesOrOperatorChanged = (
  existingRules,
  newRules,
  existingOperator,
  newOperator
) => {
  const cleanedNewRules = newRules.map((rule) => omitBy(rule, isUndefined));
  const cleanedExistingRules = existingRules.map((rule) => {
    if (
      rule.type === RULE_TYPE_TAG &&
      isUndefined(rule.label) &&
      isUndefined(rule.tagType)
    ) {
      return omitBy(rule, isUndefined);
    }
    return rule;
  });

  const hasOperatorChanged = existingOperator.value !== newOperator.value;
  const existingRulesChanged = !isEqual(cleanedNewRules, cleanedExistingRules);

  const {tag: haveTagsChanged, event: haveEventsChanged} = getRuleTypes(
    xorWith(cleanedNewRules, cleanedExistingRules, isEqual)
  );

  return {
    existingRulesChanged,
    hasOperatorChanged,
    haveTagsChanged,
    haveEventsChanged,
  };
};

/**
 * @param {string} existingName
 * @param {string} newName
 * @returns {boolean}
 */
export const checkIfExistingTeamNameHasChanged = (existingName, newName) =>
  newName !== existingName;

/**
 * @returns {{id: string, data?: {percentage: number}} | null}
 */
export const getTeamCountString = (smartTeam) => {
  const {status, currentStep, totalSteps} = smartTeam;

  const createReturnObject = (id, data) => ({
    id,
    data,
  });

  if (status === SMART_TEAM_UPDATE_STATUS.NOT_STARTED) {
    return createReturnObject('Teams.calculation.pending');
  }

  if (status === SMART_TEAM_UPDATE_STATUS.IN_PROGRESS) {
    if (!currentStep || !totalSteps) {
      return createReturnObject('Teams.calculation.inProgress');
    }

    if (currentStep >= totalSteps) {
      return createReturnObject('Teams.calculation.percentage', {
        percentage: 100,
      });
    }

    return createReturnObject('Teams.calculation.percentage', {
      percentage: Math.floor((currentStep / totalSteps) * 100),
    });
  }

  if (status === SMART_TEAM_UPDATE_STATUS.ERROR) {
    return createReturnObject('Teams.calculation.error');
  }

  return null;
};

/*
 * @param {string} tagType
 * @returns {string}
 */
export const removeSuffix = (tagType) =>
  tagType.replace(getIntegrationNameFromType(tagType) + '.', '');

/**
 * @param {object} rule
 * @returns {boolean}
 */
export const isTagMissing = (rule) => rule.label === undefined;

/**
 * @typedef {object} WhereClause
 * @property {string} column The column name for the condition (e.g., 'tag_value_id')
 * @property {string} op The operator for the condition (e.g., 'equals')
 * @property {any} value The value for the condition
 */

/**
 * @typedef {object} NestedCriteria
 * @property {string} table The table name for the nested rule (e.g., 'users_tags')
 * @property {WhereClause[]} where The where clauses for the nested rule
 * @property {string} op The operator for the nested rule (e.g., 'and')
 * @property {string} user_id_column The column name for user id (e.g., 'user_id')
 */

/**
 * @typedef {object} Rule
 * @property {string} [table] The table name if single level rule
 * @property {string} [user_id_column] The column name for user id
 * @property {WhereClause[]} [where] The where clause for single level rule
 * @property {string} [op] The operator for single level rule (e.g., 'or')
 * @property {NestedCriteria[]} [criteria] Nested rule criteria
 */

/**
 * @param {object} param
 * @param {Rule} param.rule
 * @param {object} param.tags
 * @returns {Array}
 */
export const getTagDetailsFromRule = ({rule, tags}) => {
  if (rule.table && rule.table === RULE_TABLES.TAGS) {
    const tagId = getTagIdFromRule(rule);
    return [
      {
        id: tagId,
        type: tags[tagId]?.type,
        value: tags[tagId]?.value,
      },
    ];
  }

  if (rule.criteria) {
    const nestedDetails = rule.criteria.flatMap((criterion) =>
      getTagDetailsFromRule({rule: criterion, tags})
    );
    return nestedDetails;
  }

  return [];
};

/**
 * @param {object} param
 * @param {Array} param.rules
 * @param {object} param.newRuleOperator
 * @param {number} param.ruleIndex
 * @param {Array} param.newRuleCriteria
 * @returns {object}
 */
export const buildUpdatedRule = ({
  rules,
  newRuleOperator,
  ruleIndex,
  newRuleCriteria,
}) => {
  const rule = rules[ruleIndex];

  if (newRuleCriteria) {
    return {
      ...rule,
      criteria: newRuleCriteria,
      ruleOperator: newRuleOperator,
    };
  }

  return newRuleCriteria[0];
};

/**
 * @param {object} rule Rule can be a single level rule or a nested rule with criteria
 * @param {object} criterion Criterion will have an id and a label
 * @returns {string}
 */
export const generateUniqueIdForRule = ({rule, criterion, index}) => {
  const generateId = (item) => {
    if (item.criteria) {
      const criteriaIds = map(item.criteria, generateId);
      return `${join(compact(criteriaIds), '-')}-${
        item.ruleOperator.value
      }-${index}`;
    }
    return `${item.id}-${item.label}-${index}`;
  };
  if (criterion) {
    return generateId(criterion);
  }
  return generateId(rule);
};
