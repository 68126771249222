import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const ArchiveIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path fill={colourMap[colour]} d='M2 4h20v4H2V4Z' />
    <path
      fill={colourMap[colour]}
      fillRule='evenodd'
      d='M3 9h18v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V9Zm6 3a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z'
    />
  </svg>
);

export default ArchiveIcon;
