import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {MS_TEAMS_DEFAULT_COMPANY_NAME} from '@edume/bento/integrations';
import {NEW_TRIAL_STRING} from '@edume/bento/trials';
import {LegacyInput} from '@edume/magnificent';

import {checkAndUpdateCompanyName} from '../../../state/trial/trialActions';
import {isValidCompanyName as isValidCompanyNameLength} from '../../../utils/validation';

const CompanyName = ({
  value,
  setIsFieldValid,
  isForcingValidation,
  isMsTeams,
}) => {
  const [companyName, setCompanyName] = useState(value);
  useEffect(() => {
    setCompanyName(value);
  }, [value]);

  const [isValidLength, setIsValidLength] = useState(true);
  const [isNameAvailable, setIsNameAvailable] = useState(true);

  const companyNameCheck = useSelector((state) =>
    state.getIn(['trial', 'companyNameCheck'])
  );

  const {officialName} = useSelector((state) =>
    state.getIn(['auth', 'company'])
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const isPlaceholderName =
      (isMsTeams && companyName === MS_TEAMS_DEFAULT_COMPANY_NAME) ||
      companyName.includes(NEW_TRIAL_STRING);
    if (isPlaceholderName) {
      setCompanyName('');
      setIsFieldValid(false);
    }
  }, [officialName, isMsTeams, companyName, setCompanyName, setIsFieldValid]);

  const updateValidity = (lengthStatus, availabilityStatus = false) => {
    setIsValidLength(lengthStatus);
    setIsNameAvailable(availabilityStatus);
    setIsFieldValid(lengthStatus && availabilityStatus);
  };

  useEffect(() => {
    if (isForcingValidation) {
      setIsValidLength(isValidCompanyNameLength(companyName));
    }
  }, [companyName, isForcingValidation]);

  const onSaveCompanyName = async () => {
    if (!isValidCompanyNameLength(companyName)) {
      setIsValidLength(false);
      return updateValidity(false);
    }

    const isUpdated = await dispatch(checkAndUpdateCompanyName(companyName));
    return updateValidity(true, isUpdated);
  };

  const intl = useIntl();
  const formatKey = (id) => intl.formatMessage({id});
  const companyNamePlaceholder = formatKey('Trial.company.placeholder');
  const errorText = formatKey('Error.company_name_in_use');
  const emptyErrorText = formatKey('Trial.companyNameEmptyError');

  const isFormInvalid = !isValidLength || !isNameAvailable;

  const renderErrorText = () => {
    if (companyNameCheck === 'loading') {
      return '';
    }
    return isValidLength ? errorText : emptyErrorText;
  };

  return (
    <LegacyInput
      type='text'
      placeholder={companyNamePlaceholder}
      dataAutomation='trial-company-name'
      value={companyName}
      isInvalid={isFormInvalid}
      errorText={renderErrorText()}
      onChangeValue={setCompanyName}
      onBlur={onSaveCompanyName}
      onEnterKey={onSaveCompanyName}
      includeBottomMargin={false}
      validationEmotes={false}
      ignorePrefilled={companyName?.length}
    />
  );
};

CompanyName.propTypes = {
  value: PropTypes.string,
  setIsFieldValid: PropTypes.func.isRequired,
  isForcingValidation: PropTypes.bool.isRequired,
  isMsTeams: PropTypes.bool,
};

export default CompanyName;
