import React, {useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {FormattedMessage, injectIntl} from 'react-intl';
import ReactTooltip from 'react-tooltip';

import {
  AsteriskIcon,
  Badge,
  Button,
  CopyIcon,
  DemoIcon,
  IconButton,
  LegacyHeading,
  PenIcon,
  QuestionMarkIcon,
  Well,
} from '@edume/magnificent';

import * as contentTypes from '../../../enums/contentTypes';
import {getContentBadgeColour} from '../../../utils/badges';
import {checkIsCourseInDemoLibrary} from '../../../utils/courseUtil';
import {injectFeatureFlags} from '../../../utils/featureFlags';
import {PreviewLinkButton} from '../../core';

import styles from '../styles.module.scss';

// eslint-disable-next-line complexity
const IconCourse = ({
  course,
  publishStatus,
  isResourceLibraryCourse,
  publishStatusText,
  priorityCoursesEnabled,
  sampleCourseText,
  acceptedLabel,
  firstModuleId,
  duplicateOptions,
  copyResourceLibraryCourse,
  buttonText,
  firstActivityId,
  updateCourseDetails,
  intl,
  enableEditingCourse,
  goToCourse,
  isTemplate,
  handleCopyCourse,
  isDemoContentCourse,
  handleCopyDemoCourse,
  history,
}) => {
  const {
    isTempCoursePlaceholder,
    courseKey,
    published,
    ready,
    title,
    icon,
    colour,
    isSample,
    id: courseId,
    label,
    numModuleActivities,
    contentType,
  } = course;

  const [isHovered, setIsHovered] = useState(false);

  const isCourseInDemoLibrary = checkIsCourseInDemoLibrary(course);

  const isEditableCourse = !(
    isDemoContentCourse ||
    isResourceLibraryCourse ||
    isTemplate
  );

  const isEditButtonDisabled =
    contentType === contentTypes.GUIDE && course.published;

  if (isTempCoursePlaceholder) {
    return (
      <div styleName='temp-course-placeholder'>
        <Well type='info' withMargin>
          <LegacyHeading size='tinier'>
            {intl.formatMessage({id: 'Learning.copyingMsg'})}
          </LegacyHeading>
        </Well>
      </div>
    );
  }

  const activityCountText = `${intl.formatMessage(
    {id: `Learning.${contentType}.activityCount`},
    {count: numModuleActivities || course.steps?.length || 0}
  )}`;

  const duplicateButtonTooltipText = intl.formatMessage({
    id: `Learning.${contentType}.duplicate`,
  });
  let editButtonTooltipText = intl.formatMessage({
    id: `Learning.${contentType}.edit`,
  });
  if (isEditButtonDisabled) {
    editButtonTooltipText = intl.formatMessage({id: 'Guides.unpublishToEdit'});
  }

  const leftBorderColor = {
    published: 'published',
    readyToPublish: 'successPrimary',
    unpublished: 'unpublished',
    sample: 'sample',
  }[publishStatus];

  const showSampleLabel = !isDemoContentCourse;
  const hasCourseOwnerDetails = !!(course.customer && course.parentGroup);

  const onMouseEnterWell = () => setIsHovered(true);
  const onMouseExitWell = () => setIsHovered(false);
  const onEditClick = () => {
    switch (contentType) {
      case contentTypes.COURSE:
        enableEditingCourse(course);
        break;
      case contentTypes.GUIDE:
        history.push(`/guides/${course.id}/edit${location.search}`);
        break;
      default:
        break;
    }
  };

  return (
    <div styleName='container' data-automation='course-card'>
      <Well
        type='interactive course-card'
        wellKey={courseKey}
        onMouseEnter={onMouseEnterWell}
        onMouseLeave={onMouseExitWell}
        leftBorderColor={leftBorderColor}
        onClick={
          !(isResourceLibraryCourse || isDemoContentCourse)
            ? goToCourse
            : () => null
        }
        withMargin
      >
        <div styleName='course-container'>
          <div styleName='icon-card-media'>
            {icon && (
              <div styleName='course-icon' style={{background: colour}}>
                <img
                  src={`https://edume-res.cloudinary.com/image/upload/course_icons/transparent/${icon}.png`}
                  alt=''
                  data-automation='course-icon'
                />
              </div>
            )}
            {contentType === contentTypes.GUIDE && (
              <div styleName='guide-image'>
                <img
                  src={course.courseImageUrl}
                  alt={course.title}
                  data-automation='course-icon'
                />
              </div>
            )}
            <span
              styleName={`card-content-type ${course.contentType || 'course'}`}
            >
              {intl.formatMessage({
                id: `Learning.contentType.${course.contentType || 'course'}`,
              })}
            </span>
          </div>
          <div styleName='course-info'>
            <LegacyHeading
              size='tinier'
              weight='medium'
              dataAutomation='course-title'
            >
              {title}
            </LegacyHeading>
            <LegacyHeading
              size='mini'
              colour='grey700'
              dataAutomation='course-activity-count'
            >
              {activityCountText}
            </LegacyHeading>
            {!isDemoContentCourse && (
              <div styleName='course-status-container'>
                {isEditableCourse && (
                  <div styleName='badge-wrapper'>
                    <Badge
                      textContent={publishStatusText}
                      colour={getContentBadgeColour(publishStatus)}
                      compact
                      dataAutomation='course-status'
                    />
                  </div>
                )}
                {isSample && showSampleLabel && (
                  <div styleName='badge-wrapper margin-left'>
                    <Badge
                      textContent={sampleCourseText}
                      colour={getContentBadgeColour('sample')}
                      compact
                      dataAutomation='course-label'
                    />
                  </div>
                )}
                {priorityCoursesEnabled && course.priority === 'priority' && (
                  <div styleName={`course-label`}>
                    <AsteriskIcon size='tiny' colour='blue' />
                    <LegacyHeading size='mini' colour='grey700' weight='medium'>
                      {intl.formatMessage({
                        id: 'Learning.coursePriority.card.priority',
                      })}
                    </LegacyHeading>
                  </div>
                )}
                {isCourseInDemoLibrary && (
                  <div styleName={`course-label`}>
                    <DemoIcon size='tiny' colour='blue' />
                    <LegacyHeading size='mini' colour='grey700' weight='medium'>
                      {intl.formatMessage({
                        id: 'Learning.iconCourse.label.demo',
                      })}
                    </LegacyHeading>
                  </div>
                )}
              </div>
            )}
          </div>
          {acceptedLabel && (
            <div styleName='inline-badge-wrapper'>
              <Badge
                textContent={intl.formatMessage({
                  id: `Learning.courseLabel.${label}`,
                })}
                colour={getContentBadgeColour(label)}
                compact
                badgeStyle='outlined'
                dataAutomation='course-label'
              />
            </div>
          )}
          {isTemplate && (
            <div styleName='inline-badge-wrapper'>
              <Badge
                textContent={intl.formatMessage({
                  id: `Learning.courseLabel.template`,
                })}
                colour={getContentBadgeColour('template')}
                compact
                badgeStyle='solid'
                dataAutomation='course-label'
              />
            </div>
          )}
          {isDemoContentCourse && (
            <div styleName='inline-badge-wrapper'>
              <Badge
                textContent={intl.formatMessage({
                  id: `Learning.courseLabel.demo`,
                })}
                colour={getContentBadgeColour('demo')}
                compact
                dataAutomation='course-label'
              />
            </div>
          )}
          <div styleName='course-options'>
            {isDemoContentCourse && hasCourseOwnerDetails && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                styleName='demo-course-icon'
              >
                <span
                  data-tip
                  data-for={`demoContentTip-${course.id}`}
                  data-event='click'
                  data-event-off='mouseout'
                >
                  <QuestionMarkIcon size='small' colour='grey300' />
                </span>
                <ReactTooltip
                  id={`demoContentTip-${course.id}`}
                  effect='solid'
                  place='bottom'
                  type='dark'
                  globalEventOff='click'
                  className='demoTooltip'
                >
                  <div>
                    {intl.formatMessage({
                      id: 'Learning.demo.cardTooltip1',
                    })}

                    <div>
                      <FormattedMessage
                        id='Learning.demo.cardTooltip2'
                        values={{
                          accountName: course.customer.name,
                          accountId: course.customer.id,
                          b: (text) => <b>{text}</b>,
                        }}
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id='Learning.demo.cardTooltip3'
                        values={{
                          groupName: course.parentGroup.name,
                          groupId: course.parentGroup.id,
                          b: (text) => <b>{text}</b>,
                        }}
                      />
                    </div>
                  </div>
                </ReactTooltip>
              </div>
            )}
            {(ready || published) && (
              <div styleName='mobile-hide'>
                <PreviewLinkButton
                  courseId={courseId}
                  moduleId={firstModuleId}
                  showBorder={isHovered}
                  activityId={firstActivityId}
                  intl={intl}
                  contentType={contentType}
                  guideId={courseId}
                  guidePreviewToken={course.previewToken}
                  previewInlineProps={
                    contentType === contentTypes.GUIDE
                      ? {source: '/learning'}
                      : null
                  }
                  isIconOnly
                />
              </div>
            )}
            {updateCourseDetails && isEditableCourse && (
              <>
                <div styleName='mobile-hide'>
                  <IconButton
                    showBorder={isHovered}
                    icon={<CopyIcon size='small' colour='brandPrimary' />}
                    tooltipProps={{
                      text: duplicateButtonTooltipText,
                      backgroundColor: 'black',
                      effect: 'solid',
                      place: 'bottom',
                    }}
                    popoverProps={{title: '', options: duplicateOptions}}
                    dataAutomation={`duplicate-${contentType}-button`}
                  />
                </div>
                <IconButton
                  showBorder={isHovered}
                  icon={<PenIcon size='small' colour='brandPrimary' />}
                  iconUrl='/resources/img/24_pencil.svg'
                  hoverIconUrl='/resources/img/24_pencil_white.svg'
                  disabled={isEditButtonDisabled}
                  tooltipProps={{
                    text: editButtonTooltipText,
                    backgroundColor: 'black',
                    effect: 'solid',
                    place: 'bottom',
                  }}
                  handleClick={(e) => {
                    e.stopPropagation();
                    onEditClick();
                  }}
                  dataAutomation='edit-course-details-button'
                />
              </>
            )}
            {isTemplate && (
              <Button
                type='primary'
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopyCourse();
                }}
                noMarginTop
                dataAutomation='use-template-button'
              >
                {intl.formatMessage({id: 'Learning.useThisTemplate'})}
              </Button>
            )}
            {isResourceLibraryCourse && (
              <Button
                type='primary'
                onClick={() => copyResourceLibraryCourse()}
                noMarginTop
                dataAutomation='copy-to-drafts-button'
              >
                {intl.formatMessage({id: 'Learning.copyToMyLibrary'})}
              </Button>
            )}
            {isDemoContentCourse && (
              <Button
                type='primary'
                onClick={() => handleCopyDemoCourse()}
                noMarginTop
                dataAutomation='copy-to-drafts-button'
              >
                {intl.formatMessage({id: 'Learning.copyToMyLibrary'})}
              </Button>
            )}
          </div>
        </div>
        <div styleName='desktop-hide'>
          <Button
            type='thirdary'
            onClick={
              !(isResourceLibraryCourse || isDemoContentCourse)
                ? goToCourse
                : () => null
            }
            noMarginLeft
            fullWidth
            darkIconUrl='/resources/img/24_right.svg'
            iconUrl='/resources/img/24_right_white.svg'
            iconPosition='end'
          >
            {buttonText}
          </Button>
        </div>
      </Well>
    </div>
  );
};

IconCourse.propTypes = {
  course: PropTypes.object.isRequired,
  publishStatus: PropTypes.string.isRequired,
  isResourceLibraryCourse: PropTypes.bool.isRequired,
  publishStatusText: PropTypes.string.isRequired,
  priorityCoursesEnabled: PropTypes.bool.isRequired,
  sampleCourseText: PropTypes.string.isRequired,
  acceptedLabel: PropTypes.bool.isRequired,
  firstModuleId: PropTypes.number,
  duplicateOptions: PropTypes.array,
  copyResourceLibraryCourse: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  firstActivityId: PropTypes.number,
  updateCourseDetails: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  enableEditingCourse: PropTypes.func.isRequired,
  goToCourse: PropTypes.func.isRequired,
  isTemplate: PropTypes.bool.isRequired,
  handleCopyCourse: PropTypes.func.isRequired,
  isDemoContentCourse: PropTypes.bool,
  handleCopyDemoCourse: PropTypes.func,
  history: PropTypes.object,
};

export default injectIntl(
  injectFeatureFlags(CSSModules(IconCourse, styles, {allowMultiple: true}))
);
