import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {NavLink, withRouter} from 'react-router-dom';

import {LegacyIntlHeading} from '../../../core';

import styles from './styles.module.scss';

const HeaderLink = ({
  url,
  visibleTabs,
  keys,
  icons,
  currentPath,
  onClick,
  location,
  dataAutomation,
}) => {
  const tabName = url === '/' ? 'overview' : url.replace('/', '');
  const isActiveUrl = currentPath === url;
  const activeStyle = isActiveUrl ? 'active' : '';

  let styleForItem =
    visibleTabs.indexOf(tabName) > -1 ? 'item' : 'invisible-item';

  if (isActiveUrl) {
    styleForItem += ' active';
  }

  return (
    <NavLink
      to={{pathname: url, search: location.search}}
      styleName={styleForItem}
      onClick={onClick}
      exact={url === '/'}
      data-automation={`header-item-${dataAutomation}`}
    >
      <div styleName={`iconContainer ${activeStyle}`}>{icons[url]}</div>
      <div styleName={`tabName ${activeStyle}`}>
        <LegacyIntlHeading
          textKey={keys[url]}
          weight='medium'
          size='micro'
          colour='inherit'
        />
      </div>
    </NavLink>
  );
};

HeaderLink.propTypes = {
  url: PropTypes.string.isRequired,
  visibleTabs: PropTypes.array.isRequired,
  keys: PropTypes.object,
  icons: PropTypes.object,
  currentPath: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  dataAutomation: PropTypes.string,
};

export default withRouter(
  CSSModules(HeaderLink, styles, {allowMultiple: true})
);
