import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const ExternalLinkIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19 12.8V7L10 16 8 13.9 16.8 5h-5.6V2H22v10.8h-3Z'
      fill={colourMap[colour]}
    />
    <path d='M8 7H5v12h12v-3h3v6H2V4h6v3Z' fill={colourMap[colour]} />
  </svg>
);

export default ExternalLinkIcon;
