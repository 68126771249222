import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const LinkIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='m11.4 3.7-1 1-1 4.7 3.8-3.7a3.6 3.6 0 0 1 5.1 5v.1l-3.7 3.8 4.7-1 1-1a6.3 6.3 0 0 0-9-8.9Z'
      fill={colourMap[colour]}
    />
    <path
      d='m9.5 9.4-1 1-2.8 2.8a3.6 3.6 0 0 0 5.1 5l3.7-3.6-.9 4.7-1 1a6.3 6.3 0 0 1-8.8-9l.9-.9 4.8-1Z'
      fill={colourMap[colour]}
    />
    <path d='m7.2 14.9 7.7-7.7L16.8 9 9 16.8l-2-2Z' fill={colourMap[colour]} />
  </svg>
);

export default LinkIcon;
