import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const CapIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M11 2 0 8l4 2.2V19c0 1.7 3.1 3 7 3s7-1.3 7-3v-8.8L20 9V16h2V8L11 2Zm0 3.4L6.3 8l4.7 2.6L15.7 8 11 5.4Zm0 8.6-4-2.2v6.5h.2c.8.4 2.2.7 3.8.7a10 10 0 0 0 4-.7v-6.5L11 14Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default CapIcon;
