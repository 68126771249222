import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const EmailIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M2 4h20v16H2V4Zm17 5.4L12 15 5 9.4V17h14V9.4ZM6.8 7l5.2 4.2L17.2 7H6.8Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default EmailIcon;
