import React, {AriaRole} from 'react';

import classNames from 'classnames';
import CSSModules from 'react-css-modules';

import styles from './styles.module.scss';

type Colour =
  | 'green'
  | 'amber'
  | 'purple'
  | 'blue'
  | 'red'
  | 'yellow'
  | 'grey'
  | 'light-grey'
  | 'brandPrimaryDark';

type BadgeStyle = 'default' | 'solid' | 'outlined';

type BadgeProps = {
  textContent: string;
  colour: Colour;
  badgeStyle?: BadgeStyle;
  compact?: boolean;
  dataAutomation?: string;
  breakText?: boolean;
  onClick?: () => void;
  icon?: JSX.Element;
  dataFor?: string;
  dataTip?: string;
  role?: AriaRole;
};

const Badge = ({
  textContent,
  colour,
  badgeStyle = 'default',
  compact,
  dataAutomation,
  breakText,
  onClick,
  icon,
  dataFor,
  dataTip,
  role,
}: BadgeProps): JSX.Element => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      data-for={dataFor}
      data-tip={dataTip}
      data-automation={dataAutomation}
      styleName={classNames('badge', {
        [colour]: colour,
        [badgeStyle]: badgeStyle !== 'default',
        compact: compact,
        'break-text': breakText,
        'with-icon': icon,
        clickable: onClick,
      })}
      onClick={handleClick}
      tabIndex={onClick ? 0 : undefined}
      role={role}
    >
      {textContent}
      {icon}
    </div>
  );
};

export default CSSModules(Badge, styles, {allowMultiple: true});
