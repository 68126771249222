import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const CrossIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.7 4.2 12 9.5l5.3-5.3 2.5 2.5-5.3 5.3 5.3 5.3-2.5 2.5-5.3-5.3-5.3 5.3-2.5-2.5L9.5 12 4.2 6.7l2.5-2.5Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default CrossIcon;
