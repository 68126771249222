import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const PreviewIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps) => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='m8.7 16.7 8.2-4.7-8.2-4.7v9.4Z' fill={colourMap[colour]} />
    <path
      fillRule='evenodd'
      d='M2 22V2h20v20H2ZM4.5 4.5h15v15h-15v-15Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default PreviewIcon;
