import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const CogIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z' fill={colourMap[colour]} />
    <path
      fillRule='evenodd'
      d='M7.5 18.6 6 20.1l-2.1-2 1.5-1.6a8 8 0 0 1-1.3-3H2v-3h2.1a8 8 0 0 1 1.3-3L3.9 6l2-2.1 1.6 1.5a8 8 0 0 1 3-1.3V2h3v2.1a8 8 0 0 1 3 1.3L18 3.9l2.1 2-1.5 1.6a8 8 0 0 1 1.3 3H22v3h-2.1a8 8 0 0 1-1.3 3l1.5 1.5-2 2.1-1.6-1.5a8 8 0 0 1-3 1.3V22h-3v-2.1a8 8 0 0 1-3-1.3Zm6-1.8A5 5 0 0 0 17 12a5 5 0 1 0-3.5 4.8Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default CogIcon;
