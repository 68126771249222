import React from 'react';

import CSSModules from 'react-css-modules';

import {IconProps} from '../types';
import {colourMap, DirectionOption, sizesMap} from '../utils/iconProperties';

import styles from './styles.module.scss';

type RefreshProps = IconProps & {
  direction?: DirectionOption;
};

const RefreshIcon = ({
  size = 'small',
  colour = 'textPrimary',
  direction = 'right',
  isDecorative = true,
}: RefreshProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    styleName={direction}
  >
    <path
      d='M7 7a7 7 0 1 0 10 10l2 2a10 10 0 1 1-1-15l1 1.9V2h3v8h-8V7h4l-2-1a7 7 0 0 0-9 1Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default CSSModules(RefreshIcon, styles);
