import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const PeopleIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14 7a5 5 0 0 1-1.3 3.4 8 8 0 0 1 4 9 13 13 0 0 1-15.5 0 8 8 0 0 1 4.1-9A5 5 0 0 1 9 2a5 5 0 0 1 5 5ZM9 9.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm-5.5 8a5.5 5.5 0 1 1 11 .5 10.4 10.4 0 0 1-11 0v-.5Z'
      fill={colourMap[colour]}
      fillRule='evenodd'
    />
    <path
      d='M15.4 9.8c.1-.3.3-.6.3-1a1.7 1.7 0 0 0 2.4-1.6A1.7 1.7 0 0 0 16 5.7a7 7 0 0 0-1-2.3 4.2 4.2 0 0 1 4.6 6.8 6.8 6.8 0 0 1 3.5 7.7 11 11 0 0 1-4.3 2A10 10 0 0 0 19 17c.6-.2 1.2-.4 1.7-.8v-.1c0-2-1.4-3.8-3.4-4.2a10 10 0 0 0-1.9-2.2Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default PeopleIcon;
