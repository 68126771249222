import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {LegacyInput} from '@edume/magnificent';

import {setPreferredEmail as setPreferredEmailAction} from '../../../state/trial/trialActions';
import {isValidEmail} from '../../../utils/validation';

const PreferredEmail = ({setIsFieldValid}) => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.getIn(['auth', 'email']));
  const [preferredEmail, setPreferredEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const updateValidity = (status) => {
    setIsEmailValid(status);
    setIsFieldValid(status);
  };

  const onSave = async () => {
    if (!preferredEmail) {
      updateValidity(true);
      return;
    }

    if (!isValidEmail(preferredEmail)) {
      updateValidity(false);
      return;
    }

    updateValidity(true);
    await dispatch(setPreferredEmailAction(preferredEmail));
  };

  const intl = useIntl();
  const errorText = intl.formatMessage({id: 'Admin.add.emailFormatError'});
  const placeholder = intl.formatMessage(
    {
      id: 'Trial.preferredEmail.placeholder',
    },
    {email}
  );

  return (
    <LegacyInput
      type='text'
      errorText={errorText}
      placeholder={placeholder}
      dataAutomation='trial-preferred-email-input'
      value={preferredEmail}
      isInvalid={!isEmailValid}
      onChangeValue={setPreferredEmail}
      onBlur={onSave}
      onEnterKey={onSave}
      includeBottomMargin={false}
      validationEmotes={false}
    />
  );
};

PreferredEmail.propTypes = {
  setIsFieldValid: PropTypes.func.isRequired,
};

export default PreferredEmail;
