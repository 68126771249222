import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const BellIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M9.8 2.8c0-1 1-1.8 2.2-1.8 1.2 0 2.2.8 2.2 1.8 2.7.9 4.6 3 4.6 5.7V16H21v2H3v-2h2.3V8.5c0-2.6 1.8-4.8 4.5-5.7Zm5.6 5.7V15H8.6V8.5c0-1.7 1.5-3 3.4-3s3.4 1.3 3.4 3Z'
      fill={colourMap[colour]}
    />
    <path
      d='M12 23c-1.9 0-3.4-1.3-3.4-3h6.8c0 1.7-1.5 3-3.4 3Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default BellIcon;
