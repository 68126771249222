import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const PlayIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path fill={colourMap[colour]} d='M19 12 7 19V5l12 7Z' />
  </svg>
);

export default PlayIcon;
