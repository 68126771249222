import React, {Component} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import requiredIf from 'react-required-if';

import {InfoIcon, LegacyInput} from '@edume/magnificent';

import {Hint, ListInput, MarkdownInput} from '../';

import styles from './styles.module.scss';

const DEFAULT_TITLE_LIMIT = 45;

class InputWithWarning extends Component {
  state = {
    isHintVisible: false,
    hasDismissedHint: false,
    hasFocus: false,
  };

  getLimit = () => {
    if (this.props.limit) {
      return this.props.limit;
    } else if (this.props.isTitle) {
      return DEFAULT_TITLE_LIMIT;
    }
    return null;
  };

  checkLimit = (value) => {
    const {isHintVisible, hasDismissedHint} = this.state;
    const limit = this.getLimit();

    if (!hasDismissedHint) {
      if (!isHintVisible && value.length > limit) {
        this.setState({isHintVisible: true});
      } else if (isHintVisible && value.length <= limit) {
        this.setState({isHintVisible: false});
      }
    }
  };

  onChangeValue = (value) => {
    this.checkLimit(value);
    this.props.onChangeValue(value);
  };

  dismissHint = () => {
    this.setState({hasDismissedHint: true, isHintVisible: false});
  };

  handleFocus = (e) => {
    this.checkLimit(e.value);
    this.setState({hasFocus: true});
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  handleMarkdownFocus = () => {
    this.checkLimit(this.props.value);
    this.setState({hasFocus: true});
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  handleBlur = () => {
    this.setState({hasFocus: false});
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  render() {
    const {
      type,
      rows,
      value,
      placeholder,
      title,
      isTitle,
      hintText,
      errorText,
      optionalText,
      isInvalid,
      autoFocus,
      disabled,
      width,
      tabIndex,
      validationStyles,
      validationEmotes,
      typeIcons,
      ignorePrefilled,
      includeBottomMargin,
      errorKey,
      deleteEnabled,
      onDelete,
      deleteTooltipKey,
      disabledTooltipKey,
      onEnterKey,
      theme,
      hintPosition,
      hintVerticalPosition,
      intl,
      dataAutomation,
      readyStatusDataAutomation,
      showCountTextContainer,
    } = this.props;
    const limit = this.getLimit();

    const characterCount = value.length;
    const countText =
      characterCount === 0
        ? ''
        : intl.formatMessage(
            {id: 'Input.characterCount'},
            {count: characterCount}
          );

    const longTextHintId = isTitle && !limit ? 'LONG_TITLE_TEXT' : 'LONG_TEXT';

    const getInputComponent = () => {
      switch (type) {
        case 'markdown':
          return (
            <MarkdownInput
              isInvalid={isInvalid}
              title={title}
              validationStyles='icon'
              optionalText={optionalText}
              value={value}
              placeholder={placeholder}
              onChangeValue={this.onChangeValue}
              onFocus={this.handleMarkdownFocus}
              onBlur={this.handleBlur}
              dataAutomation={dataAutomation}
              readyStatusDataAutomation={readyStatusDataAutomation}
            />
          );
        case 'list':
          return (
            <ListInput
              type='text'
              value={value}
              onChangeValue={this.onChangeValue}
              placeholder={placeholder}
              isInvalid={isInvalid}
              errorKey={errorKey}
              title={title}
              validationStyles={validationStyles}
              includeBottomMargin={includeBottomMargin}
              deleteEnabled={deleteEnabled}
              onDelete={onDelete}
              deleteTooltipKey={deleteTooltipKey}
              disabledTooltipKey={disabledTooltipKey}
              countText={countText}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              theme={theme}
              dataAutomation={dataAutomation}
              readyStatusDataAutomation={readyStatusDataAutomation}
            />
          );

        default:
          return (
            <LegacyInput
              type='text'
              value={value}
              onChangeValue={this.onChangeValue}
              rows={rows}
              placeholder={placeholder}
              title={title}
              hintText={hintText}
              errorText={errorText}
              optionalText={optionalText}
              onEnterKey={onEnterKey}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              validationStyles={validationStyles}
              validationEmotes={validationEmotes}
              typeIcons={typeIcons}
              ignorePrefilled={ignorePrefilled}
              isInvalid={isInvalid}
              autoFocus={autoFocus}
              disabled={disabled}
              width={width}
              tabIndex={tabIndex}
              includeBottomMargin={includeBottomMargin}
              theme={theme}
              showCount={true}
              countText={type === 'markdown' ? '' : countText}
              dataAutomation={dataAutomation}
              readyStatusDataAutomation={readyStatusDataAutomation}
              showCountTextContainer={showCountTextContainer}
            />
          );
      }
    };

    return (
      <div styleName='hint-container'>
        <div
          styleName={`floating-hint ${hintPosition} ${hintVerticalPosition}`}
        >
          <Hint
            hintId={longTextHintId}
            textValues={{
              count: characterCount,
              charsOverLimit: characterCount - limit,
            }}
            buttonKey='ok'
            trianglePosition={hintVerticalPosition === 'below' ? 'tl' : 'bl'}
            icon={<InfoIcon />}
            dismissHint={this.dismissHint}
            visible={this.state.isHintVisible && this.state.hasFocus}
          />
        </div>
        <div>{getInputComponent()}</div>
      </div>
    );
  }
}

InputWithWarning.propTypes = {
  // Input props
  value: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  rows: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string,
  isTitle: PropTypes.bool,
  hintText: PropTypes.string,
  errorText: PropTypes.string,
  optionalText: PropTypes.string,
  onEnterKey: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  validationStyles: PropTypes.oneOf(['normal', 'alwaysValid', 'hide', 'icon']),
  isInvalid: PropTypes.bool.isRequired,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  validationEmotes: PropTypes.bool,
  typeIcons: PropTypes.bool,
  ignorePrefilled: PropTypes.bool,
  width: PropTypes.oneOf(['short', 'long', 'fullWidth']),
  tabIndex: PropTypes.number,
  includeBottomMargin: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
  readyStatusDataAutomation: PropTypes.string,

  // ListInput props
  errorKey: PropTypes.string,
  deleteEnabled: PropTypes.bool,
  onDelete: PropTypes.func,
  deleteTooltipKey: PropTypes.string,
  disabledTooltipKey: PropTypes.string,

  // Other
  limit: requiredIf(PropTypes.number, (props) => !props.isTitle),
  type: PropTypes.oneOf(['normal', 'list', 'markdown']),
  hintPosition: PropTypes.oneOf(['right', 'center']),
  hintVerticalPosition: PropTypes.oneOf(['above', 'below']),
  dataAutomation: PropTypes.string,
  showCountTextContainer: PropTypes.bool,

  intl: PropTypes.object.isRequired,
};

InputWithWarning.defaultProps = {
  hintPosition: 'right',
  hintVerticalPosition: 'above',
};

export default injectIntl(
  CSSModules(InputWithWarning, styles, {allowMultiple: true})
);
