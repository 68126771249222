import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const PenIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='m20.5 6.3-2.8-2.8-2.2 2.1 2.9 2.9 2-2.2ZM17 9.9 14 7 5 16.2v2.9h2.9l9.1-9.2Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default PenIcon;
