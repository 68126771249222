import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const SadFilledIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={colourMap[colour]}
      fillRule='evenodd'
      d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm-2-12a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm6.266 5.652a6 6 0 0 0-8.486 0 1 1 0 1 0 1.415 1.414 4 4 0 0 1 5.656 0 1 1 0 0 0 1.415-1.414ZM16 8a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z'
    />
  </svg>
);

export default SadFilledIcon;
