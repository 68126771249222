import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const HomeIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M12 2 1 13l1.5 2L4 13.5V22h16v-8.5l1.5 1.5 1.5-2L12 2Zm5 9.2-5-5-5 5V19h10v-7.8Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default HomeIcon;
