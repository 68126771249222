import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const ClockIcon = ({
  size = 'small',
  colour = 'textPrimary',
  isDecorative = true,
}: IconProps): JSX.Element => (
  <svg
    aria-hidden={isDecorative}
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M11 13.5V6h2v5.5h5v2h-7Z' fill={colourMap[colour]} />
    <path
      fillRule='evenodd'
      d='M23 12a11 11 0 1 1-22 0 11 11 0 0 1 22 0Zm-3 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default ClockIcon;
